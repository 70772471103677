import { useState } from 'react';

import styled from 'styled-components';
import Text from '~/app/shared/components/Text';
import { useRouterQueryUtils } from '~/app/shared/hooks';
import { useComposableTrackCtx } from '~/features/tracks/contexts/ComposableTrackCtx';
import { useTrackContentNavigationCtx } from '~/features/tracks/contexts/TrackContentNavigationCtx';
import { useTrackStepLabels } from '~/features/tracks/hooks/useTrackStepLabels';
import { TrackSectionWithItems } from '~/features/tracks/types';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import colors from '~/services/colors';
import BackgroundPanel from '~/app/shared/components/BackgroundPanel';
import ContentDescription from '~/app/stand-alone-shared/components/ContentDescription';
import TrackItemCard from '~/app/tracks/components/TrackItemCard';
import { isEmpty, map, size, toLower } from 'lodash-es';
import { Paper, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '~/app/shared/components/Icon';

const ContentItemBackgroundPanel = styled(BackgroundPanel)`
  left: 0;
  top: -40px;
`;

const ClickedInfo = styled.span`
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  margin-left: 12px;
`;

interface TrackSectionDetailProps {
  section: TrackSectionWithItems;
  currentTrackSectionIndex: number;
  trackSectionsCount: number;
}

const TrackSectionDetailPage = (props: TrackSectionDetailProps) => {
  const { section, currentTrackSectionIndex, trackSectionsCount } = props;

  const { description, items } = section;
  const stepsCount = size(items);

  const { getSectionOrItemIndex } = useComposableTrackCtx();
  const { onGoToPageIndex } = useTrackContentNavigationCtx();

  const handleSectionItemClick = (itemPageIndex: number) => {
    onGoToPageIndex(itemPageIndex);
  };

  const [linkClicked, setLinkClicked] = useState('');
  const stepLabels = useTrackStepLabels();
  const { getUrlWithoutQueryString } = useRouterQueryUtils();
  const sectionURL = `${window.location.origin}${getUrlWithoutQueryString(['page', 'section'])}section=${section.id}`;
  // Reset link clicked if we've navigated to another section
  if (!isEmpty(linkClicked) && linkClicked != section.id) {
    setLinkClicked('');
  }
  const handleClick = (e) => {
    navigator.clipboard.writeText(sectionURL);
    setLinkClicked(section.id);
    e.preventDefault();
  };

  return (
    <BaseContainer>
      <ContentItemBackgroundPanel height="120px" />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          width: '100%',
          padding: '16px 38px 20px 30px',
          marginTop: 5,
          borderRadius: '8px',
        }}
      >
        <Stack flex={1} spacing={2}>
          <Stack flex={1} spacing={0.5}>
            <Text medium size="h2" color={colors.neutral900}>
              <Link to={sectionURL} onClick={handleClick}>
                {section.name} &nbsp;
                <Icon name="link" width={14} height={14} />
              </Link>
              {linkClicked === section.id && <ClickedInfo>Copied</ClickedInfo>}
            </Text>
            <Text size="h5" color={colors.neutral700}>
              Section {currentTrackSectionIndex} of {trackSectionsCount} • {stepsCount}
              {stepsCount === 1
                ? ` ${toLower(stepLabels.singular)}`
                : ` ${toLower(stepLabels.plural)}`}
            </Text>
          </Stack>

          {description && <ContentDescription noPadding description={description} />}

          {map(items, (item) => {
            const index = getSectionOrItemIndex(item.track_id!, item.content_item.public_id);

            return (
              <TrackItemCard item={item} onClick={() => handleSectionItemClick(index)} isSummary />
            );
          })}
        </Stack>
      </Paper>
    </BaseContainer>
  );
};

export default TrackSectionDetailPage;
