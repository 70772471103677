import { Box, Typography } from '@mui/material';
import { InsightWidget, InsightWidgetHeader } from '~/common/components/InsightWidget';

import {
  axisClasses,
  BarChart,
  ChartsXAxis,
  useDrawingArea,
  useAxisTooltip,
  ChartsAxisData,
} from '@mui/x-charts-pro';
import MapIcon from '@mui/icons-material/Map';
import { map } from 'lodash-es';

import { TrackCompletionStatusInsightRange } from '../types';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

import {
  ChartTooltipContent as BaseChartTooltipContent,
  ChartTooltipContentHeader,
  ChartTooltipContentDivider,
  ChartTooltipContentBody,
} from '~/common/components/ChartTooltipContent';

function transformChartDataset(data: TrackCompletionStatusInsightRange) {
  const rangeKey = `${data.range.start}-${data.range.end}`;
  let rangeLabel = `${rangeKey}%`;

  if (data.range.start === 0) {
    rangeLabel = '0%';
  } else if (data.range.end === 100) {
    rangeLabel = '100%';
  }

  return {
    rangeKey,
    rangeLabel,
    completed: data.states.completed,
    in_progress: data.states.in_progress,
    not_started: data.states.not_started,
    total: data.states.completed + data.states.in_progress + data.states.not_started,
  };
}

/**
 * Custom component to display chart title at the bottom right
 * corner of the chart.
 *
 * @returns
 */
function ChartTitleLabel() {
  const { left, width } = useDrawingArea();

  return (
    <ChartsXAxis
      disableLine
      disableTicks
      label="Number of Users vs Track Completion [%]"
      labelStyle={{
        fontSize: '0.875rem',
        lineHeight: '1.3',
        textAnchor: 'end',
        transform: `translate(0, 8px)`,
      }}
      slotProps={{
        axisLabel: {
          x: left + width,
        },
      }}
    />
  );
}

function ChartTooltipContent() {
  const axisData = useAxisTooltip();

  if (!axisData) {
    return null;
  }

  const { axisFormattedValue, seriesItems } = axisData;

  const totalSeriesItem = seriesItems[0];

  return (
    <BaseChartTooltipContent>
      <ChartTooltipContentHeader title={axisFormattedValue} />
      <ChartTooltipContentDivider />
      <ChartTooltipContentBody>
        <Typography
          component="span"
          sx={{
            fontWeight: 700,
            fontSize: '0.875rem',
          }}
        >
          {totalSeriesItem.formattedValue}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontWeight: 300,
            fontSize: '0.875rem',
          }}
        >
          {' '}
          Assignments
        </Typography>
      </ChartTooltipContentBody>
      <ChartTooltipContentDivider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '5px',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: '0.75rem',
            lineHeight: 1.4,
            fontWeight: 500,
          }}
        >
          Click to review
        </Typography>
      </Box>
    </BaseChartTooltipContent>
  );
}

type TrackCompletionStatusInsightWidgetProps = {
  trackId: number;
  queryExpression: string;
  onChartItemClick?: (range: TrackCompletionStatusInsightRange) => void;
};

function TrackCompletionStatusInsightWidget(props: TrackCompletionStatusInsightWidgetProps) {
  const { trackId, queryExpression, onChartItemClick } = props;

  const { data: trackCompletionStatusInsightData, status } = useQuery({
    ...queries.tracks.completionStatusInsights(trackId, queryExpression),
  });

  const distribution = trackCompletionStatusInsightData ?? [];
  const chartDataset = map(distribution, transformChartDataset);

  const handleChartItemClick = (axis: ChartsAxisData) => {
    const axisData = distribution[axis.dataIndex];

    if (!axisData) {
      return;
    }

    onChartItemClick?.(axisData);
  };

  return (
    <InsightWidget>
      <InsightWidgetHeader
        title="Track Completion Status"
        icon={<MapIcon fontSize="small" sx={{ color: '#00B8D4' }} />}
      />

      <Box
        sx={{
          flex: 1,
          minHeight: '255px',
        }}
      >
        <BarChart
          dataset={chartDataset}
          loading={status === 'loading'}
          xAxis={[
            {
              id: 'percentage',
              scaleType: 'band',
              dataKey: 'rangeLabel',
              // @ts-expect-error For some reason this is not typed
              categoryGapRatio: 0.1,
              tickLabelPlacement: 'middle',
              disableTicks: true,
            },
          ]}
          series={[
            {
              id: 'total',
              dataKey: 'total',
              color: '#A779CA',
            },
          ]}
          onAxisClick={(_, axis) => {
            if (axis) {
              handleChartItemClick(axis);
            }
          }}
          height={255}
          margin={{
            top: 20,
            right: 0,
            bottom: 55,
            left: 50,
          }}
          grid={{ horizontal: true, vertical: false }}
          sx={{
            [`& .${axisClasses.tickLabel}`]: {
              fontWeight: 400,
              lineHeight: 1.16,
            },
          }}
          slotProps={{
            legend: {
              padding: {
                left: 50,
              },
              position: {
                horizontal: 'left',
                vertical: 'bottom',
              },
            },
          }}
          slots={{
            axisContent: ChartTooltipContent,
          }}
        >
          <ChartTitleLabel />
        </BarChart>
      </Box>
    </InsightWidget>
  );
}

export { TrackCompletionStatusInsightWidget };
