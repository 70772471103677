import { Button, Stack, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { get, map } from 'lodash-es';
import qs from 'query-string';
import {
  InsightsModal,
  InsightsModalHeader,
  InsightsModalContent,
  InsightsModalFooter,
} from '~/common/components/InsightsModal';
import { Track, TrackCompletionStatusInsightRange } from '../types';
import { mapRoute } from '~/services/requests';

import { AssignmentStatusInsightWidget } from '~/features/contentitems/components/AssignmentStatusInsightWidget';
import { TrackCompletionStatusInsightWidget } from './TrackCompletionStatusInsightWidget';
import { TrackItemsProgressInsightWidget } from './TrackItemsProgressInsightWidget';
import { TrackCompletionStatusByItemInsightWidget } from './TrackCompletionStatusByItemInsightWidget';
import DynamicFilterBar from '~/app/shared/components/DynamicFilterBar';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';
import { RQLFiltersProvider } from '~/app/rosters/RQLFiltersContext';
import { useRQLRouteFilters } from '~/app/backoffice/hooks';
import Loading from '~/app/shared/components/Loading';
import rql from '~/vendor/rql';
import { AssignmentStatusInsightState } from '~/features/contentitems/types';
import { rqlExpressionToObject } from '~/app/backoffice/utils';

type TrackInsightsModalProps = {
  track: Track;
  onClose: () => void;
};

type TrackInsightsModalContentProps = TrackInsightsModalProps & {
  dynamicFilters: any;
};

function TrackInsightsModalContent(props: TrackInsightsModalContentProps) {
  const { track, dynamicFilters } = props;

  const history = useHistory();

  const {
    expression: routeRqlExpression,
    filterObj,
    addFilter,
    removeFilter,
    updateFilter,
    updateFilters,
  } = useRQLRouteFilters(
    '',
    map(dynamicFilters, (item) => item.filter),
    // @ts-expect-error - Do not add page size filter
    null,
    null
  );

  const urlFilters = get(filterObj, 'filters', null) as any;

  const trackQueryExpression = qs.stringify({
    content_item_id: track.id,
  });

  const queryExpressionArr = [trackQueryExpression];
  if (routeRqlExpression) {
    queryExpressionArr.push(routeRqlExpression);
  }

  const queryExpression = queryExpressionArr.join('&');

  const trackAssignmentsRoute = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: track.public_id_and_slug,
  });

  const handleGoToAssignmentsWithFilters = (filterExpression: string) => {
    let queryExpression = filterExpression;
    if (routeRqlExpression) {
      queryExpression += `&${routeRqlExpression}`;
    }

    const filtersRqlExpression = rql(rqlExpressionToObject(queryExpression));

    history.push(`${trackAssignmentsRoute}?${filtersRqlExpression}`);
  };

  const handleClickAssignmentStatusInsight = (state: AssignmentStatusInsightState) => {
    const chartRqlExpression = rql({
      state: state.state,
    });

    handleGoToAssignmentsWithFilters(chartRqlExpression);
  };

  const handleClickTrackCompletionStatusInsight = (range: TrackCompletionStatusInsightRange) => {
    const isEqualRange = range.range.start === range.range.end;

    const chartRqlExpression = isEqualRange
      ? rql({
          progress: {
            $eq: range.range.start,
          },
        })
      : rql({
          progress: {
            $ge: range.range.start,
            $le: range.range.end,
          },
        });

    handleGoToAssignmentsWithFilters(chartRqlExpression);
  };

  return (
    <InsightsModalContent>
      <DynamicFilterBar
        contentType="track"
        filters={urlFilters}
        dynamicFilters={dynamicFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
        updateFilters={updateFilters}
        enableSegments={false}
      />

      <Stack spacing="20px">
        <Typography variant="h6">Engagement</Typography>
        <Grid container spacing="20px">
          <Grid size={{ sm: 12, md: 'auto' }}>
            <AssignmentStatusInsightWidget
              queryExpression={queryExpression}
              onChartItemClick={handleClickAssignmentStatusInsight}
            />
          </Grid>
          <Grid size={{ sm: 12, md: 'grow' }}>
            <TrackCompletionStatusInsightWidget
              trackId={track.id}
              queryExpression={queryExpression}
              onChartItemClick={handleClickTrackCompletionStatusInsight}
            />
          </Grid>
          <Grid size={12}>
            <TrackCompletionStatusByItemInsightWidget
              track={track}
              queryExpression={queryExpression}
            />
          </Grid>
          <Grid size={12}>
            <TrackItemsProgressInsightWidget track={track} queryExpression={queryExpression} />
          </Grid>
        </Grid>
      </Stack>
    </InsightsModalContent>
  );
}

type TrackInsightsModalFooterProps = {
  track: Track;
  dynamicFilters: any;
  onClose: () => void;
};

function TrackInsightsModalFooter(props: TrackInsightsModalFooterProps) {
  const { track, dynamicFilters, onClose } = props;

  const history = useHistory();

  const { expression } = useRQLRouteFilters(
    '',
    map(dynamicFilters, (item) => item.filter),
    // @ts-expect-error - Do not add page size filter
    null,
    null
  );

  const trackAssignmentsRoute = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: track.public_id_and_slug,
  });

  const handleViewAssignments = () => {
    history.push(`${trackAssignmentsRoute}?${expression}`);
  };

  return (
    <InsightsModalFooter>
      <Button onClick={onClose}>Close</Button>
      <Button onClick={handleViewAssignments} variant="contained">
        View Assignments
      </Button>
    </InsightsModalFooter>
  );
}

function TrackInsightsModal(props: TrackInsightsModalProps) {
  const { track } = props;

  const history = useHistory();
  const location = useLocation();

  const { data: dynamicFilters, status } = useQuery({
    ...queries.tracks.insightsRQLFilters({
      initialFilters: ['created', 'user_group', 'user_org_lead'],
    }),
  });

  const handleClose = () => {
    // TODO: This is a hack to close the modal. We should find a better way to do this.
    // This is because we need to clean the filters from the url when the modal is closed.
    history.replace(location.pathname);
  };

  return (
    <RQLFiltersProvider>
      <InsightsModal onClose={handleClose}>
        <InsightsModalHeader title={`${track.name} Insights`} onClose={handleClose} />

        {status === 'loading' ? (
          <InsightsModalContent>
            <Loading />
          </InsightsModalContent>
        ) : (
          <TrackInsightsModalContent {...props} dynamicFilters={dynamicFilters} />
        )}

        {status === 'success' && (
          <TrackInsightsModalFooter
            track={track}
            dynamicFilters={dynamicFilters}
            onClose={handleClose}
          />
        )}
      </InsightsModal>
    </RQLFiltersProvider>
  );
}

export { TrackInsightsModal };
